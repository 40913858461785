import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  setSearch,
  setArticles,
  setSubsettings,
  setOrdersNzShopify,
  setSelectedSupplier,
  setDropshipOrders,
  setOrderToDate,
  setOrderFromDate,
  setPurchasedOrders,
} from "../../actions";

export default function ShippingInfoModal({
  order,
  setCount,
  count,
  index,
  fullfillment,
  setFullfillment,
  carrier,
}) {
  const order_from_date = useSelector((state) => state.order_from_date);
  const order_to_date = useSelector((state) => state.order_to_date);
  const dispatch = useDispatch();
  const purchased_orders = useSelector((state) => state.purchased_orders);
  const [trackingnumber, setTrackingNumber] = useState("");
  // const carrier = useSelector(state => state.carrier)

  //
  // const handleChange = (event) => {
  //   console.log(index, event.target.value)
  //   setCarrier(event.target.value)
  //   let obj = fullfillment
  //   if(event.target.value === "NA"){
  //       obj.tracking_urls[index] = ""
  //       obj.tracking_company = ""
  //   }
  //   if(event.target.value === "AMSe"){
  //     obj.tracking_urls[index] = "https://www.aftership.com/track/" + obj.tracking_numbers[index]
  //     obj.tracking_company = "AMSe"
  //   }
  //   if(event.target.value === "Aramex"){
  //     obj.tracking_urls[index] = "www.aramex.co.nz/tools/track?l=" + obj.tracking_numbers[index]
  //     obj.tracking_company = "Aramex"
  //   }
  //   if(event.target.value === "UBI"){
  //     obj.tracking_urls[index] = "https://www.aftership.com/track/" + obj.tracking_numbers[index]
  //     obj.tracking_company = "UBI"
  //   }
  //   if(event.target.value === "YANWEN"){
  //     obj.tracking_urls[index] = "https://track.yw56.com.cn/en/querydel?nums=" + obj.tracking_numbers[index]
  //     obj.tracking_company = "YANWEN"
  //   }
  //   if(event.target.value === "DHL"){
  //     obj.tracking_urls[index] = "https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=" + obj.tracking_numbers[index]
  //     obj.tracking_company = "DHL"
  //   }
  //   if(event.target.value === "FEDEX"){
  //     obj.tracking_urls[index] = "https://www.aftership.com/track/" + obj.tracking_numbers[index]
  //     obj.tracking_company = "FEDEX"
  //   }
  //   if(event.target.value === "other"){
  //     obj.tracking_urls[index] = "https://www.aftership.com/track/" + obj.tracking_numbers[index]
  //     obj.tracking_company = "other"
  //   }
  //
  //   setFullfillment(obj)
  //   setCount(count + 1)
  // };

  const handleTracking = () => {
    let obj = fullfillment;
    obj.tracking_numbers[index] = trackingnumber;
    if (obj.tracking_company === "NA") {
      obj.tracking_urls[index] = "";
    }
    if (obj.tracking_company === "AMSe") {
      obj.tracking_urls[index] =
        "https://myams.com.au/tracking.aspx?id=" +
        trackingnumber;
    }

    if (obj.tracking_company === "AUSFF") {
      obj.tracking_urls[index] =
        "https://auspost.com.au/mypost/beta/track/details/" + trackingnumber;
    }

    if (obj.tracking_company === "Aramex") {
      obj.tracking_urls[index] =
        "https://www.aramex.co.nz/tools/track?l=" + trackingnumber;
    }
    if (obj.tracking_company === "UBI") {
      obj.tracking_urls[index] =
        "https://www.aftership.com/track/ubi-logistics/" + trackingnumber;
    }
    if (obj.tracking_company === "YANWEN") {
      obj.tracking_urls[index] =
        "https://track.yw56.com.cn/en/querydel?nums=" + trackingnumber;
    }
    if (obj.tracking_company === "DHL") {
      obj.tracking_urls[index] =
        "https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=" +
        trackingnumber;
    }
    if (obj.tracking_company === "FEDEX") {
      obj.tracking_urls[index] =
        "https://www.aftership.com/track/" + trackingnumber;
    }
    if (obj.tracking_company === "4PX") {
      obj.tracking_urls[index] =
        "https://track.4px.com/?#/result/0/" + trackingnumber;
    }
    if (obj.tracking_company === "dpex") {
      obj.tracking_urls[index] =
        "https://dpex.com/track-and-trace/" + trackingnumber;
    }
    if (obj.tracking_company === "nzpost") {
      obj.tracking_urls[index] =
        "https://www.nzpost.co.nz/tools/tracking/item/" + trackingnumber;
    }
    if (obj.tracking_company === "Aramex Australia") {
      obj.tracking_urls[index] =
        "https://www.aramex.com.au/tools/track?l=" + trackingnumber;
    }
    if (obj.tracking_company === "aupost") {
      obj.tracking_urls[index] =
        "https://auspost.com.au/mypost/track/#/details/" + trackingnumber;
    }
    if (obj.tracking_company === "PFL") {
      obj.tracking_urls[index] =
        "https://www.aftership.com/track/" +
        trackingnumber;
    }
    if (obj.tracking_company === "IGX") {
      obj.tracking_urls[index] =
        "https://tracking.icumulus.cloud/" + trackingnumber;
    }
    if (obj.tracking_company === "PBT") {
      obj.tracking_urls[index] =
        "https://www.pbt.nz/tracking-result/?consignmentNumber=" + trackingnumber;
    }
    if (obj.tracking_company === "YunExpress") {
      obj.tracking_urls[index] =
        "https://www.yuntrack.com/parcelTracking?id=" + trackingnumber;
    }
    if (obj.tracking_company === "PassTheParcel") {
      obj.tracking_urls[index] =
        "https://www.passtheparcel.co.nz/Pass/Track/" + trackingnumber;
    }
    if (obj.tracking_company === "other") {
      obj.tracking_urls[index] =
        "https://www.aftership.com/track/" + trackingnumber;
    }
    if(obj.tracking_company == "NZcourier")
      {
        obj.tracking_urls[index] = 
        "https://www.aftership.com/track/newzealand-couriers/" + trackingnumber;
      }

      //new added
      if (obj.tracking_company === "India Post") {
        obj.tracking_urls[index] =
          "https://www.aftership.com/track/india-post-int/" +
          trackingnumber;
      }
      if (obj.tracking_company === "China Post") {
        obj.tracking_urls[index] =
          "https://www.aftership.com/track/china-post/" + trackingnumber;
      }
      if (obj.tracking_company === "EQT") {
        obj.tracking_urls[index] =
          "https://www.51tracking.com/17post56-tracking-en?number=" + trackingnumber;
      }
      if (obj.tracking_company === "SF International") {
        obj.tracking_urls[index] =
          "https://www.aftership.com/track/sf-express/" + trackingnumber;
      }
      if (obj.tracking_company === "GoSweetSpot") {
        obj.tracking_urls[index] =
          "https://track.gosweetspot.com/m?id=" + trackingnumber;
      }

      if (obj.tracking_company === "Cainiao") {
        obj.tracking_urls[index] =
          "https://www.aftership.com/track/cainiao/" + trackingnumber;
      }

    setFullfillment(obj);
    setCount(count + 1);
  };

  useEffect(() => {
    handleTracking();
    setCount(count + 1);
  }, [trackingnumber, carrier]);

  console.log("fullfillment", fullfillment);
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "10% 10% 35% 30% 25%",
        gridGap: "2%",
        marginTop: "1%",
      }}
    >
      <div>{order.orderid}</div>
      <div></div>
      <div>
        <input
          style={{ width: "310px" }}
          type="text"
          placeholder="Enter Tracking Number"
          value={fullfillment.tracking_numbers[index]}
          onChange={(event) => setTrackingNumber(event.target.value)}
        />
      </div>
      <div></div>
      <div
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          let obj = fullfillment;
          obj.tracking_numbers = obj.tracking_numbers.slice(0, -1);
          obj.tracking_urls = obj.tracking_urls.slice(0, -1);
          setFullfillment(obj);
          setCount(count + 1);
        }}
      >
        <CloseIcon />
      </div>
    </div>
  );
}
